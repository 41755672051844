// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './Login';
import Sidebar from './Sidebar';
import Clientes from './Clientes';
import Usuarios from './Usuarios';
import EnvioWhatsApp from './EnvioWhatsApp';
import EnvioEmail from './EnvioEmail';
import EnvioSMS from './EnvioSMS';
import Plantillas from './Plantillas';
import { AuthProvider, useAuth } from './AuthContext';
import ProtectedRoute from './ProtectedRoute';
import HistorialEnvios from './HistorialEnvios';
const AppContent = () => {
  const { currentUser } = useAuth();

  return (
    <div style={{ display: 'flex' }}>
      {currentUser && <Sidebar />}
      <div style={{ flex: 1, padding: '20px' }}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/clientes"
            element={
              <ProtectedRoute requiredRole="user">
                <Clientes />
              </ProtectedRoute>
            }
          />
          <Route
            path="/usuarios"
            element={
              <ProtectedRoute requiredRole="super_admin">
                <Usuarios />
              </ProtectedRoute>
            }
          />
          <Route
            path="/plantillas"
            element={
              <ProtectedRoute requiredRole="admin">
                <Plantillas />
              </ProtectedRoute>
            }
          />
          <Route
            path="/whatsapp"
            element={
              <ProtectedRoute requiredRole="user">
                <EnvioWhatsApp />
              </ProtectedRoute>
            }
          />
          <Route
            path="/email"
            element={
              <ProtectedRoute requiredRole="user">
                <EnvioEmail />
                
              </ProtectedRoute>
            }
          />
          <Route
            path="/sms"
            element={
              <ProtectedRoute requiredRole="user">
                <EnvioSMS />
              </ProtectedRoute>
            }
          />
               <Route
            path="/historial"
            element={
              <ProtectedRoute roles={['user', 'admin', 'super_admin']}>
                <HistorialEnvios />
              </ProtectedRoute>
            }
          />
          <Route path="/not-authorized" element={<div>No Autorizado</div>} />
          <Route path="*" element={!currentUser ? <Navigate to="/login" /> : <Navigate to="/whatsapp" />} />
        </Routes>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
};

export default App;


