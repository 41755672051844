// src/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const ProtectedRoute = ({ children, requiredRole }) => {
  const { currentUser, hasRole } = useAuth();

  // Redirigir al inicio de sesión si el usuario no está logueado
  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  // Redirigir a "no autorizado" si el usuario no tiene el rol requerido
  if (requiredRole && !hasRole(requiredRole)) {
    return <Navigate to="/not-authorized" />;
  }

  return children;
};

export default ProtectedRoute;
