import React, { useState, useEffect } from 'react';
import { db } from './firebase';
import { collection, getDoc, updateDoc, doc, onSnapshot } from 'firebase/firestore';
import { TextField, Button, Select, MenuItem } from '@mui/material';
import DataTable from 'react-data-table-component';
import axios from 'axios';

const limpiarID = (id) => id.trim().replace(/[^a-zA-Z0-9_-]/g, '');

const HistorialEnvios = () => {
  const [historial, setHistorial] = useState([]);
  const [filtros, setFiltros] = useState({
    clienteNombre: '',
    telefono: '',
    status: '',
    tipoEnvio: '',
  });
  const [selectedRows, setSelectedRows] = useState([]);

  const cloudFunctionUrls = {
    whatsapp: 'https://us-central1-mascotamas-prospectos.cloudfunctions.net/enviarWhatsApp',
    sms: 'https://us-central1-mascotamas-prospectos.cloudfunctions.net/enviarSMS',
    email: 'https://us-central1-mascotamas-prospectos.cloudfunctions.net/enviarEmail'
  };

  useEffect(() => {
    // Escuchar cambios en tiempo real en la colección de historialEnvios
    const unsubscribe = onSnapshot(collection(db, 'historialEnvios'), (snapshot) => {
      const historialList = [];
      snapshot.forEach((docSnapshot) => {
        const documentData = docSnapshot.data();

        // Iterar sobre el array de mensajes dentro del documento
        const mensajesArray = documentData.mensajes || [];
        mensajesArray.forEach((mensaje) => {
          historialList.push({
            docId: docSnapshot.id, // Agregar el ID del documento para futuras actualizaciones
            clienteId: documentData.clienteId,
            clienteNombre: documentData.clienteNombre,
            ...mensaje,
          });
        });
      });

      setHistorial(historialList);
      console.log('Historial actualizado en tiempo real:', historialList);
    });

    return () => unsubscribe(); // Cleanup listener on unmount
  }, []);

  const handleFilterChange = (e) => {
    setFiltros({ ...filtros, [e.target.name]: e.target.value });
  };

  const handleReenviar = async (tipoEnvio) => {
    const promises = selectedRows.map(async (envio) => {
      const data = {
        phone: envio.telefono,
        mensaje: envio.mensaje,
        mediaUrl: envio.mediaUrl || ''
      };

      try {
        const response = await axios.post(cloudFunctionUrls[tipoEnvio], data, {
          headers: { 'Content-Type': 'application/json' }
        });

        if (response.data.success) {
          console.log(`Mensaje reenviado con éxito a través de ${tipoEnvio}:`, response.data.data);
          alert(`Mensaje reenviado con éxito a través de ${tipoEnvio}: ${response.data.data}`);
          await updateHistorial(envio, tipoEnvio, 'success', response.data.data);
        } else {
          console.warn(`Error en el reenvío a través de ${tipoEnvio}:`, response.data.error);
          await updateHistorial(envio, tipoEnvio, 'error', response.data.error);
        }
      } catch (error) {
        console.error(`Error al reenviar el mensaje a través de ${tipoEnvio}:`, error);
        await updateHistorial(envio, tipoEnvio, 'error', error.message);
      }
    });

    await Promise.all(promises);
    alert('Proceso de reenvío completado.');
  };

  // Función para actualizar el historial de envíos en Firestore y el estado local
  const updateHistorial = async (envio, tipoEnvio, status, resultado) => {
    try {
      const historialDocRef = doc(db, 'historialEnvios', limpiarID(envio.docId)); // Referencia al documento de Firestore
      const historialSnapshot = await getDoc(historialDocRef);

      if (historialSnapshot.exists()) {
        const mensajesArray = historialSnapshot.data().mensajes || [];

        // Actualizar el mensaje específico en el array de mensajes
        const mensajesActualizados = mensajesArray.map((mensaje) => {
          if (mensaje.telefono === envio.telefono && mensaje.mensaje === envio.mensaje) {
            return { ...mensaje, status, resultado, canal: tipoEnvio, fecha: new Date() };
          }
          return mensaje;
        });

        await updateDoc(historialDocRef, { mensajes: mensajesActualizados });
        console.log('Historial actualizado en Firestore con el nuevo estado del mensaje.');

        // Actualizar el estado local de React con los datos modificados
        setHistorial((prevHistorial) =>
          prevHistorial.map((item) =>
            item.telefono === envio.telefono && item.mensaje === envio.mensaje
              ? { ...item, status, resultado, canal: tipoEnvio }
              : item
          )
        );
      } else {
        console.error('El documento de historial de envíos no existe.');
      }
    } catch (error) {
      console.error('Error al actualizar el historial de envío:', error);
    }
  };

  // Filtrar los datos en tiempo real
  const filteredData = historial.filter((envio) =>
    (!filtros.clienteNombre || envio.clienteNombre.toLowerCase().includes(filtros.clienteNombre.toLowerCase())) &&
    (!filtros.telefono || envio.telefono.toLowerCase().includes(filtros.telefono.toLowerCase())) &&
    (!filtros.status || envio.status.toLowerCase() === filtros.status.toLowerCase()) &&
    (!filtros.tipoEnvio || envio.canal.toLowerCase() === filtros.tipoEnvio.toLowerCase())
  );

  const columns = [
    { name: 'Nombre Cliente', selector: (row) => row.clienteNombre, sortable: true },
    { name: 'Teléfono', selector: (row) => row.telefono, sortable: true },
    { name: 'Mensaje', selector: (row) => row.mensaje, sortable: true },
    { name: 'Canal', selector: (row) => row.canal, sortable: true },
    { name: 'Estado', selector: (row) => row.status, sortable: true },
    { name: 'Resultado', selector: (row) => row.resultado, sortable: false }
  ];

  return (
    <div>
      <h2>Historial de Envíos</h2>
      <div style={{ marginBottom: 20 }}>
        <TextField
          name="clienteNombre"
          label="Nombre Cliente"
          value={filtros.clienteNombre}
          onChange={handleFilterChange}
          style={{ marginRight: 10 }}
        />
        <TextField
          name="telefono"
          label="Teléfono"
          value={filtros.telefono}
          onChange={handleFilterChange}
          style={{ marginRight: 10 }}
        />
        <Select
          name="status"
          value={filtros.status}
          onChange={handleFilterChange}
          displayEmpty
          style={{ marginRight: 10 }}
        >
          <MenuItem value="">Filtrar por Estado</MenuItem>
          <MenuItem value="success">Exitoso</MenuItem>
          <MenuItem value="error">Error</MenuItem>
        </Select>
        <Select
          name="tipoEnvio"
          value={filtros.tipoEnvio}
          onChange={handleFilterChange}
          displayEmpty
        >
          <MenuItem value="">Filtrar por Canal</MenuItem>
          <MenuItem value="whatsapp">WhatsApp</MenuItem>
          <MenuItem value="sms">SMS</MenuItem>
          <MenuItem value="email">Email</MenuItem>
        </Select>
      </div>

      {/* Asegúrate de que la tabla se renderice correctamente con los datos filtrados */}
      <DataTable
        columns={columns}
        data={filteredData}
        selectableRows
        onSelectedRowsChange={(state) => setSelectedRows(state.selectedRows)}
        pagination
        highlightOnHover
        noDataComponent="No hay datos para mostrar" // Mostrar un mensaje cuando no hay datos
      />

      <div style={{ marginTop: 20 }}>
        <Button
          onClick={() => handleReenviar('whatsapp')}
          variant="contained"
          color="primary"
          style={{ marginRight: 10 }}
        >
          Reenviar por WhatsApp
        </Button>
        <Button
          onClick={() => handleReenviar('sms')}
          variant="contained"
          color="secondary"
          style={{ marginRight: 10 }}
        >
          Reenviar por SMS
        </Button>
        <Button
          onClick={() => handleReenviar('email')}
          variant="contained"
          style={{ backgroundColor: 'green', color: 'white' }}
        >
          Reenviar por Email
        </Button>
      </div>
    </div>
  );
};

export default HistorialEnvios;

