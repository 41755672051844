import React, { useState, useEffect } from 'react';
import { db } from './firebase';
import { collection, getDocs, addDoc, deleteDoc, updateDoc, doc, getDoc } from 'firebase/firestore';
import { TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle, Select, MenuItem, Card, CardContent, CardActions, Typography, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const Plantillas = () => {
  const [plantillas, setPlantillas] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingTemplate, setEditingTemplate] = useState(null);
  const [newTemplate, setNewTemplate] = useState({
    tipoEnvio: '',
    categoriaCliente: '',
    texto: '',
    urlMultimedia: '',
  });

  const camposDisponibles = [
    { label: 'Nombre del Cliente', value: '{nombre}' },
    { label: 'Categoría', value: '{categoria}' },
    { label: 'Ciudad', value: '{ciudad}' },
    { label: 'Estado', value: '{estado}' },
    { label: 'País', value: '{pais}' },
    { label: 'Dirección', value: '{direccion}' },
    { label: 'Teléfono', value: '{telefono}' },
    { label: 'Website', value: '{website}' }
  ];

  useEffect(() => {
    const fetchPlantillas = async () => {
      const plantillasCollection = collection(db, 'plantillas');
      const plantillasSnapshot = await getDocs(plantillasCollection);
      const plantillasList = plantillasSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setPlantillas(plantillasList);
    };

    fetchPlantillas();
  }, []);

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingTemplate(null);
    setNewTemplate({
      tipoEnvio: '',
      categoriaCliente: '',
      texto: '',
      urlMultimedia: '',
    });
  };

  const handleSaveTemplate = async () => {
    try {
      if (editingTemplate) {
        const templateDocRef = doc(db, 'plantillas', editingTemplate.id);

        const templateSnapshot = await getDoc(templateDocRef);
        if (!templateSnapshot.exists()) {
          alert('El documento no existe y no se puede actualizar.');
          return;
        }

        await updateDoc(templateDocRef, newTemplate);
        setPlantillas((prev) =>
          prev.map((template) =>
            template.id === editingTemplate.id ? { id: editingTemplate.id, ...newTemplate } : template
          )
        );
      } else {
        const plantillasCollection = collection(db, 'plantillas');
        const newDocRef = await addDoc(plantillasCollection, newTemplate);
        setPlantillas((prev) => [...prev, { id: newDocRef.id, ...newTemplate }]);
      }
      handleCloseDialog();
    } catch (error) {
      console.error('Error al guardar la plantilla:', error);
      alert('Hubo un error al guardar la plantilla.');
    }
  };

  const handleEditTemplate = (template) => {
    setEditingTemplate(template);
    setNewTemplate(template);
    setOpenDialog(true);
  };

  const handleDeleteTemplate = async (id) => {
    try {
      await deleteDoc(doc(db, 'plantillas', id));
      setPlantillas((prev) => prev.filter((template) => template.id !== id));
    } catch (error) {
      console.error('Error al eliminar la plantilla:', error);
      alert('Hubo un error al eliminar la plantilla.');
    }
  };

  const handleInsertCampo = (campo) => {
    setNewTemplate({
      ...newTemplate,
      texto: newTemplate.texto + ' ' + campo
    });
  };

  return (
    <div>
      <h2>Gestión de Plantillas</h2>
      <Button onClick={handleOpenDialog} variant="outlined" style={{ marginBottom: 20 }}>
        Crear Nueva Plantilla
      </Button>

      {/* Listado de Plantillas */}
      <List>
        {plantillas.map((template) => (
          <Card key={template.id} style={{ marginBottom: 10 }}>
            <CardContent>
              <Typography variant="h6" component="div">
                {template.tipoEnvio} - {template.categoriaCliente}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {template.texto}
              </Typography>
              {template.urlMultimedia && (
                <img
                  src={template.urlMultimedia}
                  alt="Previsualización"
                  style={{ width: '100%', height: 'auto', marginTop: 10 }}
                />
              )}
            </CardContent>
            <CardActions>
              <Button onClick={() => handleEditTemplate(template)} startIcon={<EditIcon />}>
                Editar
              </Button>
              <Button onClick={() => handleDeleteTemplate(template.id)} color="error" startIcon={<DeleteIcon />}>
                Eliminar
              </Button>
            </CardActions>
          </Card>
        ))}
      </List>

      {/* Diálogo para añadir/editar plantilla */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{editingTemplate ? 'Editar Plantilla' : 'Crear Nueva Plantilla'}</DialogTitle>
        <DialogContent>
          <Select
            fullWidth
            value={newTemplate.tipoEnvio}
            onChange={(e) => setNewTemplate({ ...newTemplate, tipoEnvio: e.target.value })}
            displayEmpty
            style={{ marginBottom: 10 }}
          >
            <MenuItem value="">Seleccionar Tipo de Envío</MenuItem>
            <MenuItem value="sms">SMS</MenuItem>
            <MenuItem value="whatsapp">WhatsApp</MenuItem>
            <MenuItem value="email">Email</MenuItem>
          </Select>
          <Select
            fullWidth
            value={newTemplate.categoriaCliente}
            onChange={(e) => setNewTemplate({ ...newTemplate, categoriaCliente: e.target.value })}
            displayEmpty
            style={{ marginBottom: 10 }}
          >
            <MenuItem value="">Seleccionar Categoría de Cliente</MenuItem>
            <MenuItem value="petshop">Petshop</MenuItem>
            <MenuItem value="peluqueria canina">Peluquería Canina</MenuItem>
            <MenuItem value="veterinaria">Veterinaria</MenuItem>
          </Select>
          <TextField
            label="Texto Personalizado"
            multiline
            fullWidth
            value={newTemplate.texto}
            onChange={(e) => setNewTemplate({ ...newTemplate, texto: e.target.value })}
            style={{ marginBottom: 10 }}
          />
          <div>
            {camposDisponibles.map((campo) => (
              <Button
                key={campo.value}
                onClick={() => handleInsertCampo(campo.value)}
                variant="outlined"
                style={{ margin: '5px' }}
              >
                {campo.label}
              </Button>
            ))}
          </div>
          <TextField
            label="URL de Contenido Multimedia"
            fullWidth
            value={newTemplate.urlMultimedia}
            onChange={(e) => setNewTemplate({ ...newTemplate, urlMultimedia: e.target.value })}
            style={{ marginBottom: 10 }}
          />
          {newTemplate.urlMultimedia && (
            <img
              src={newTemplate.urlMultimedia}
              alt="Previsualización Contenido Multimedia"
              style={{ width: '100%', height: 'auto', marginTop: 10 }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancelar</Button>
          <Button onClick={handleSaveTemplate} variant="contained" color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Plantillas;
