// src/Sidebar.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemText, Button, Typography } from '@mui/material';
import { useAuth } from './AuthContext';

const Sidebar = () => {
  const { userName, userRole, logout } = useAuth();

  const menuItems = [
    { text: 'Usuarios', path: '/usuarios', roles: ['super_admin'] },
    { text: 'Clientes', path: '/clientes', roles: ['user', 'admin', 'super_admin'] },
    { text: 'Plantillas', path: '/plantillas', roles: ['admin', 'super_admin'] }, 
    { text: 'Envío de WhatsApp', path: '/whatsapp', roles: ['user', 'admin', 'super_admin'] },
    { text: 'Envío de Email', path: '/email', roles: ['user', 'admin', 'super_admin'] },
    { text: 'Envío de SMS', path: '/sms', roles: ['user', 'admin', 'super_admin'] },
  ];

  const filteredMenuItems = menuItems.filter((item) => item.roles.includes(userRole));

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 240,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: 240,
          boxSizing: 'border-box',
        },
      }}
    >
      <List>
        {/* Mostrar los datos del usuario logueado */}
        {userName && userRole && (
          <div style={{ textAlign: 'center', marginTop: '20px', marginBottom: '20px' }}>
            <Typography variant="h6">Bienvenido, {userName}</Typography>
            <Typography variant="body2">Rol: {userRole}</Typography>
          </div>
        )}

        {filteredMenuItems.map((item) => (
          <ListItem button component={Link} to={item.path} key={item.text} disableGutters>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
          <ListItem button component={Link} to="/historial">
    <ListItemText primary="Historial de Envíos" />
  </ListItem>
      </List>

      {/* Botón de Cerrar Sesión */}
      <Button onClick={logout} sx={{ margin: '20px' }} variant="outlined" color="secondary">
        Cerrar Sesión
      </Button>
    </Drawer>
  );
};

export default Sidebar;

