import React, { useState, useEffect } from 'react';
import { db } from './firebase';
import { collection, getDocs, addDoc, updateDoc, query, where } from 'firebase/firestore';
import { TextField, Button, Select, MenuItem } from '@mui/material';
import DataTable from 'react-data-table-component';
import axios from 'axios';

const EnvioWhatsApp = () => {
  const [clientes, setClientes] = useState([]);
  const [plantillas, setPlantillas] = useState([]);
  const [selectedClientes, setSelectedClientes] = useState([]);
  const [selectedPlantilla, setSelectedPlantilla] = useState('');
  const [sending, setSending] = useState(false);
  const [filtros, setFiltros] = useState({
    nombre: '',
    categoria: '',
    ciudad: '',
  });

  // URL de tu Cloud Function desplegada
  const cloudFunctionUrl = 'https://us-central1-mascotamas-prospectos.cloudfunctions.net/enviarWhatsApp';

  useEffect(() => {
    const fetchClientes = async () => {
      const clientesCollection = collection(db, 'clientes');
      const clientesSnapshot = await getDocs(clientesCollection);
      const clientesList = clientesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setClientes(clientesList);
    };

    const fetchPlantillas = async () => {
      const plantillasCollection = collection(db, 'plantillas');
      const plantillasSnapshot = await getDocs(plantillasCollection);
      const plantillasList = plantillasSnapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((plantilla) => plantilla.tipoEnvio === 'whatsapp');
      setPlantillas(plantillasList);
    };

    fetchClientes();
    fetchPlantillas();
  }, []);

  // Función para formatear el teléfono
  const formatearTelefono = (telefono, pais) => {
    // Elimina espacios en blanco y caracteres especiales
    let telefonoFormateado = telefono.replace(/[^0-9]/g, '');

    // Eliminar el "+" al principio si existe
    if (telefonoFormateado.startsWith('00')) {
      telefonoFormateado = telefonoFormateado.substring(2);
    } else if (telefonoFormateado.startsWith('+')) {
      telefonoFormateado = telefonoFormateado.substring(1);
    }

    // Formatear según el país
    switch (pais) {
      case 'AR': // Argentina
        if (telefonoFormateado.startsWith('54')) {
          if (!telefonoFormateado.startsWith('549')) {
            telefonoFormateado = '549' + telefonoFormateado.substring(2); // Agrega el "9" después del "54"
          }
        } else {
          telefonoFormateado = '549' + telefonoFormateado; // Si no comienza con "54", lo agrega
        }
        break;
      // Agrega otros países y sus reglas aquí si es necesario
      default:
        // Devolver el número tal cual si no se especifica un formato especial para el país
        break;
    }

    return telefonoFormateado;
  };

  const handleEnviarWhatsApp = async () => {
    if (!selectedPlantilla) {
      alert('Por favor, seleccione una plantilla de WhatsApp.');
      return;
    }

    setSending(true);

    try {
      const promises = selectedClientes.map(async (cliente) => {
        const plantillaSeleccionada = plantillas.find((p) => p.id === selectedPlantilla);
        let mensajePersonalizado = reemplazarVariables(plantillaSeleccionada.texto || '', cliente);
        const mediaUrl = plantillaSeleccionada.urlMultimedia || '';
        
        // Formatea el número de teléfono antes de enviar
        let phone = cliente.telefono ? cliente.telefono.toString() : '';
        const paisCliente = 'AR'; // Puedes ajustar esto para usar el país correcto
        phone = formatearTelefono(phone, paisCliente);

        // Validar si el teléfono es válido
        if (!phone || phone.length < 10) { // Ajusta el número mínimo de dígitos según el país
          console.warn(`Cliente omitido por número de teléfono inválido: ${cliente.nombre}`);
          return Promise.resolve(); // No hacer la solicitud para este cliente
        }

        // Mantener los saltos de línea en el mensaje
        mensajePersonalizado = mensajePersonalizado.replace(/\\n/g, '\n');

        const data = {
          phone,
          mensaje: mensajePersonalizado || '',
          mediaUrl: mediaUrl || ''
        };

        try {
          const response = await axios.post(cloudFunctionUrl, data, {
            headers: { 'Content-Type': 'application/json' }
          });

          if (response.data.success) {
            console.log('Mensaje enviado con éxito:', response.data.data);
            await saveHistorialEnvio(cliente, data, 'success', response.data.data, 'whatsapp');
          } else {
            console.warn('Error en el envío:', response.data.error);
            await saveHistorialEnvio(cliente, data, 'error', response.data.error, 'whatsapp');
          }
        } catch (error) {
          console.error('Error al enviar el mensaje:', error);
          await saveHistorialEnvio(cliente, data, 'error', error.message, 'whatsapp');
        }
      });

      await Promise.all(promises);
      alert('Proceso de envío completado.');
    } catch (error) {
      console.error('Error al enviar los mensajes:', error);
      alert('Hubo un error al enviar los mensajes.');
    } finally {
      setSending(false);
    }
  };

  const saveHistorialEnvio = async (cliente, data, status, message, canal) => {
    try {
      const historialCollection = collection(db, 'historialEnvios');
      const q = query(historialCollection, where('clienteId', '==', cliente.id));
      const historialSnapshot = await getDocs(q);

      if (!historialSnapshot.empty) {
        const historialDocRef = historialSnapshot.docs[0].ref;
        const historialData = historialSnapshot.docs[0].data();
        
        const nuevosMensajes = historialData.mensajes || [];
        nuevosMensajes.push({
          telefono: data.phone,
          mensaje: data.mensaje,
          mediaUrl: data.mediaUrl,
          status,
          resultado: message,
          canal,
          fecha: new Date()
        });

        await updateDoc(historialDocRef, { mensajes: nuevosMensajes });
      } else {
        await addDoc(historialCollection, {
          clienteId: cliente.id,
          clienteNombre: cliente.nombre,
          mensajes: [{
            telefono: data.phone,
            mensaje: data.mensaje,
            mediaUrl: data.mediaUrl,
            status,
            resultado: message,
            canal,
            fecha: new Date()
          }]
        });
      }
    } catch (error) {
      console.error('Error al guardar el historial de envío:', error);
    }
  };

  const reemplazarVariables = (templateText, cliente) => {
    let texto = templateText;
    Object.keys(cliente).forEach((key) => {
      texto = texto.replace(new RegExp(`{${key}}`, 'gi'), cliente[key] || '');
    });
    return texto;
  };

  const handleRowSelected = (selectedRows) => {
    setSelectedClientes(selectedRows.selectedRows);
  };

  const handleFilterChange = (e) => {
    setFiltros({ ...filtros, [e.target.name]: e.target.value });
    const filtered = clientes.filter((cliente) => {
      return Object.keys(filtros).every((key) =>
        cliente[key].toLowerCase().includes(filtros[key].toLowerCase())
      );
    });
    setClientes(filtered);
  };

  const columns = [
    { name: 'Nombre', selector: (row) => row.nombre, sortable: true },
    { name: 'Categoría', selector: (row) => row.categoria, sortable: true },
    { name: 'Ciudad', selector: (row) => row.ciudad, sortable: true },
    { name: 'Teléfono', selector: (row) => row.telefono, sortable: true },
  ];

  return (
    <div>
      <h2>Envío de WhatsApp</h2>
      <div style={{ marginBottom: 20 }}>
        <TextField
          name="nombre"
          label="Nombre"
          value={filtros.nombre}
          onChange={handleFilterChange}
          style={{ marginRight: 10 }}
        />
        <TextField
          name="categoria"
          label="Categoría"
          value={filtros.categoria}
          onChange={handleFilterChange}
          style={{ marginRight: 10 }}
        />
        <TextField
          name="ciudad"
          label="Ciudad"
          value={filtros.ciudad}
          onChange={handleFilterChange}
        />
      </div>
      
      <Select
        fullWidth
        value={selectedPlantilla}
        onChange={(e) => setSelectedPlantilla(e.target.value)}
        displayEmpty
        style={{ marginBottom: 20 }}
      >
        <MenuItem value="">Seleccionar Plantilla de WhatsApp</MenuItem>
        {plantillas.map((plantilla) => (
          <MenuItem key={plantilla.id} value={plantilla.id}>
            {plantilla.texto}
          </MenuItem>
        ))}
      </Select>

      <DataTable
        columns={columns}
        data={clientes}
        selectableRows
        onSelectedRowsChange={handleRowSelected}
        pagination
        highlightOnHover
      />

      <Button
        onClick={handleEnviarWhatsApp}
        variant="contained"
        color="primary"
        disabled={sending || selectedClientes.length === 0}
        style={{ marginTop: 20 }}
      >
        {sending ? 'Enviando...' : 'Enviar WhatsApp'}
      </Button>
    </div>
  );
};

export default EnvioWhatsApp;
