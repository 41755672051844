// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions'; // Importa getFunctions
// Importa solo si necesitas analytics
// import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCq6Z4tUEafbcHWsDLAt7bCKgsBbdCmOX0",
  authDomain: "mascotamas-prospectos.firebaseapp.com",
  projectId: "mascotamas-prospectos",
  storageBucket: "mascotamas-prospectos.appspot.com",
  messagingSenderId: "412934378554",
  appId: "1:412934378554:web:cd37d151c28524a376d103",
  measurementId: "G-KN89GQD7N0"
};

// Inicializa Firebase
const app = initializeApp(firebaseConfig);

// Inicializa Auth, Firestore y Functions
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app); // Exporta el módulo de Functions



// Inicializa analytics solo si es necesario
// const analytics = getAnalytics(app); // Comentado para evitar el error de "unused-vars"

export default app;