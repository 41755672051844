// src/Clientes.js
import React, { useEffect, useState } from 'react';
import { db } from './firebase';
import { collection, getDocs, addDoc, deleteDoc, updateDoc, doc } from 'firebase/firestore';
import DataTable from 'react-data-table-component';
import { TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

const Clientes = () => {
  const [clientes, setClientes] = useState([]);
  const [filteredClientes, setFilteredClientes] = useState([]);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState({ categoria: '', ciudad: '', estado: '', pais: '', nombre: '' });
  const [selectedRows, setSelectedRows] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [newClient, setNewClient] = useState({
    nombre: '',
    categoria: '',
    ciudad: '',
    estado: '',
    pais: '',
    direccion: '',
    telefono: '',
    website: '',
    imagenes: ['', '', ''],
  });
  const [editingClient, setEditingClient] = useState(null);

  useEffect(() => {
    const fetchClientes = async () => {
      const clientesCollection = collection(db, 'clientes');
      const clientesSnapshot = await getDocs(clientesCollection);
      const clientesList = clientesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setClientes(clientesList);
      setFilteredClientes(clientesList);
    };

    fetchClientes();
  }, []);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    filterData(e.target.value, filter);
  };

  const handleFilterChange = (e) => {
    const newFilter = { ...filter, [e.target.name]: e.target.value };
    setFilter(newFilter);
    filterData(search, newFilter);
  };

  const filterData = (searchText, filters) => {
    let data = clientes;

    if (searchText) {
      data = data.filter((cliente) =>
        Object.values(cliente).some(
          (value) =>
            value !== null &&
            value !== undefined &&
            value.toString().toLowerCase().includes(searchText.toLowerCase())
        )
      );
    }

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        data = data.filter(
          (cliente) =>
            cliente[key] &&
            cliente[key].toString().toLowerCase().includes(filters[key].toLowerCase())
        );
      }
    });

    setFilteredClientes(data);
  };

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const handleDialogOpen = () => setOpenDialog(true);
  const handleDialogClose = () => setOpenDialog(false);

  const handleEditDialogOpen = (cliente) => {
    setEditingClient(cliente);
    setEditDialogOpen(true);
  };
  const handleEditDialogClose = () => {
    setEditingClient(null);
    setEditDialogOpen(false);
  };

  const handleAddClient = async () => {
    try {
      const clientesCollection = collection(db, 'clientes');
      await addDoc(clientesCollection, newClient);
      setClientes([...clientes, newClient]);
      setFilteredClientes([...clientes, newClient]);
      setNewClient({
        nombre: '',
        categoria: '',
        ciudad: '',
        estado: '',
        pais: '',
        direccion: '',
        telefono: '',
        website: '',
        imagenes: ['', '', ''],
      });
      handleDialogClose();
    } catch (error) {
      console.error('Error al añadir cliente:', error);
    }
  };

  const handleEditClient = async () => {
    try {
      const clientDocRef = doc(db, 'clientes', editingClient.id);
      await updateDoc(clientDocRef, editingClient);
      const updatedClientes = clientes.map((cliente) =>
        cliente.id === editingClient.id ? editingClient : cliente
      );
      setClientes(updatedClientes);
      setFilteredClientes(updatedClientes);
      handleEditDialogClose();
    } catch (error) {
      console.error('Error al editar cliente:', error);
    }
  };

  const handleDeleteClient = async (id) => {
    try {
      await deleteDoc(doc(db, 'clientes', id));
      const updatedClientes = clientes.filter((cliente) => cliente.id !== id);
      setClientes(updatedClientes);
      setFilteredClientes(updatedClientes);
    } catch (error) {
      console.error('Error al eliminar cliente:', error);
    }
  };

  const columns = [
    { name: 'Nombre', selector: (row) => row.nombre, sortable: true },
    { name: 'Categoría', selector: (row) => row.categoria, sortable: true },
    { name: 'Ciudad', selector: (row) => row.ciudad, sortable: true },
    { name: 'Estado', selector: (row) => row.estado, sortable: true },
    { name: 'País', selector: (row) => row.pais, sortable: true },
    { name: 'Teléfono', selector: (row) => row.telefono, sortable: true },
    { name: 'Website', selector: (row) => row.website, sortable: true },
    {
      name: 'Acciones',
      cell: (row) => (
        <>
          <Button onClick={() => handleEditDialogOpen(row)}>Editar</Button>
          <Button onClick={() => handleDeleteClient(row.id)}>Eliminar</Button>
        </>
      ),
    },
  ];

  return (
    <div>
      <h2>Gestión de Clientes</h2>
      <TextField label="Buscar" value={search} onChange={handleSearch} style={{ marginBottom: 20 }} />
      
      <div style={{ display: 'flex', gap: '10px' }}>
        {['categoria', 'ciudad', 'estado', 'pais', 'nombre'].map((field) => (
          <TextField
            key={field}
            name={field}
            label={field.charAt(0).toUpperCase() + field.slice(1)}
            value={filter[field]}
            onChange={handleFilterChange}
          />
        ))}
      </div>

      <Button onClick={handleDialogOpen} style={{ marginTop: 20 }} variant="outlined">
        Añadir Cliente
      </Button>

      <DataTable
        columns={columns}
        data={filteredClientes}
        selectableRows
        onSelectedRowsChange={handleRowSelected}
        pagination
        highlightOnHover
      />

      {/* Diálogo para añadir nuevo cliente */}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Añadir Nuevo Cliente</DialogTitle>
        <DialogContent>
          <TextField label="Nombre" fullWidth value={newClient.nombre} onChange={(e) => setNewClient({ ...newClient, nombre: e.target.value })} />
          <TextField label="Categoría" fullWidth value={newClient.categoria} onChange={(e) => setNewClient({ ...newClient, categoria: e.target.value })} />
          <TextField label="Ciudad" fullWidth value={newClient.ciudad} onChange={(e) => setNewClient({ ...newClient, ciudad: e.target.value })} />
          <TextField label="Estado" fullWidth value={newClient.estado} onChange={(e) => setNewClient({ ...newClient, estado: e.target.value })} />
          <TextField label="País" fullWidth value={newClient.pais} onChange={(e) => setNewClient({ ...newClient, pais: e.target.value })} />
          <TextField label="Dirección" fullWidth value={newClient.direccion} onChange={(e) => setNewClient({ ...newClient, direccion: e.target.value })} />
          <TextField label="Teléfono" fullWidth value={newClient.telefono} onChange={(e) => setNewClient({ ...newClient, telefono: e.target.value })} />
          <TextField label="Website" fullWidth value={newClient.website} onChange={(e) => setNewClient({ ...newClient, website: e.target.value })} />
          {/* Previsualización y edición de imágenes */}
          <TextField
            label="Imagen 1"
            fullWidth
            value={newClient.imagenes[0]}
            onChange={(e) =>
              setNewClient({
                ...newClient,
                imagenes: [e.target.value, newClient.imagenes[1], newClient.imagenes[2]],
              })
            }
          />
          <img
            src={newClient.imagenes[0]}
            alt="Previsualización Imagen 1"
            style={{ width: '100px', height: '100px', marginTop: '10px', objectFit: 'cover' }}
          />
                    <TextField
            label="Imagen 2"
            fullWidth
            value={newClient.imagenes[1]}
            onChange={(e) =>
              setNewClient({
                ...newClient,
                imagenes: [newClient.imagenes[0], e.target.value, newClient.imagenes[2]],
              })
            }
          />
          <img
            src={newClient.imagenes[1]}
            alt="Previsualización Imagen 2"
            style={{ width: '100px', height: '100px', marginTop: '10px', objectFit: 'cover' }}
          />
          <TextField
            label="Imagen 3"
            fullWidth
            value={newClient.imagenes[2]}
            onChange={(e) =>
              setNewClient({
                ...newClient,
                imagenes: [newClient.imagenes[0], newClient.imagenes[1], e.target.value],
              })
            }
          />
          <img
            src={newClient.imagenes[2]}
            alt="Previsualización Imagen 3"
            style={{ width: '100px', height: '100px', marginTop: '10px', objectFit: 'cover' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancelar</Button>
          <Button onClick={handleAddClient}>Guardar</Button>
        </DialogActions>
      </Dialog>

      {/* Diálogo para editar cliente */}
      {editingClient && (
        <Dialog open={editDialogOpen} onClose={handleEditDialogClose}>
          <DialogTitle>Editar Cliente</DialogTitle>
          <DialogContent>
            <TextField
              label="Nombre"
              fullWidth
              value={editingClient.nombre}
              onChange={(e) =>
                setEditingClient({ ...editingClient, nombre: e.target.value })
              }
            />
            <TextField
              label="Categoría"
              fullWidth
              value={editingClient.categoria}
              onChange={(e) =>
                setEditingClient({ ...editingClient, categoria: e.target.value })
              }
            />
            <TextField
              label="Ciudad"
              fullWidth
              value={editingClient.ciudad}
              onChange={(e) =>
                setEditingClient({ ...editingClient, ciudad: e.target.value })
              }
            />
            <TextField
              label="Estado"
              fullWidth
              value={editingClient.estado}
              onChange={(e) =>
                setEditingClient({ ...editingClient, estado: e.target.value })
              }
            />
            <TextField
              label="País"
              fullWidth
              value={editingClient.pais}
              onChange={(e) =>
                setEditingClient({ ...editingClient, pais: e.target.value })
              }
            />
            <TextField
              label="Dirección"
              fullWidth
              value={editingClient.direccion}
              onChange={(e) =>
                setEditingClient({ ...editingClient, direccion: e.target.value })
              }
            />
            <TextField
              label="Teléfono"
              fullWidth
              value={editingClient.telefono}
              onChange={(e) =>
                setEditingClient({ ...editingClient, telefono: e.target.value })
              }
            />
            <TextField
              label="Website"
              fullWidth
              value={editingClient.website}
              onChange={(e) =>
                setEditingClient({ ...editingClient, website: e.target.value })
              }
            />
            {/* Previsualización y edición de imágenes */}
            <TextField
              label="Imagen 1"
              fullWidth
              value={editingClient.imagenes[0]}
              onChange={(e) =>
                setEditingClient({
                  ...editingClient,
                  imagenes: [e.target.value, editingClient.imagenes[1], editingClient.imagenes[2]],
                })
              }
            />
            <img
              src={editingClient.imagenes[0]}
              alt="Previsualización Imagen 1"
              style={{ width: '100px', height: '100px', marginTop: '10px', objectFit: 'cover' }}
            />
            <TextField
              label="Imagen 2"
              fullWidth
              value={editingClient.imagenes[1]}
              onChange={(e) =>
                setEditingClient({
                  ...editingClient,
                  imagenes: [editingClient.imagenes[0], e.target.value, editingClient.imagenes[2]],
                })
              }
            />
            <img
              src={editingClient.imagenes[1]}
              alt="Previsualización Imagen 2"
              style={{ width: '100px', height: '100px', marginTop: '10px', objectFit: 'cover' }}
            />
            <TextField
              label="Imagen 3"
              fullWidth
              value={editingClient.imagenes[2]}
              onChange={(e) =>
                setEditingClient({
                  ...editingClient,
                  imagenes: [editingClient.imagenes[0], editingClient.imagenes[1], e.target.value],
                })
              }
            />
            <img
              src={editingClient.imagenes[2]}
              alt="Previsualización Imagen 3"
              style={{ width: '100px', height: '100px', marginTop: '10px', objectFit: 'cover' }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleEditDialogClose}>Cancelar</Button>
            <Button onClick={handleEditClient}>Guardar Cambios</Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default Clientes;
