// src/EnvioSMS.js
import React from 'react';

const EnvioSMS = () => {
  return (
    <div>
      <h2>Envío de SMS</h2>
      {/* Contenido del componente */}
    </div>
  );
};

export default EnvioSMS;
