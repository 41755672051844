// src/EnvioEmail.js
import React from 'react';

const EnvioEmail = () => {
  return (
    <div>
      <h2>Envío de Email</h2>
      {/* Contenido del componente */}
    </div>
  );
};

export default EnvioEmail;
