import React, { useEffect, useState } from 'react';
import { auth, db, functions } from './firebase';
import { createUserWithEmailAndPassword, sendPasswordResetEmail, sendEmailVerification } from 'firebase/auth';
import { collection, getDocs, doc, deleteDoc, updateDoc, setDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

const Usuarios = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserPassword, setNewUserPassword] = useState('');
  const [newUserRole, setNewUserRole] = useState('user');

  useEffect(() => {
    const obtenerUsuarios = async () => {
      try {
        const usuariosSnapshot = await getDocs(collection(db, 'users'));
        const usuariosList = usuariosSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setUsuarios(usuariosList);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    obtenerUsuarios();
  }, []);

  const handleAddUser = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, newUserEmail, newUserPassword);
      const user = userCredential.user;

      await setDoc(doc(db, 'users', user.uid), {
        email: newUserEmail,
        role: newUserRole,
        emailVerified: user.emailVerified,
        createdAt: new Date(),
      });

      setUsuarios([...usuarios, { id: user.uid, email: newUserEmail, role: newUserRole, emailVerified: user.emailVerified }]);
      setNewUserEmail('');
      setNewUserPassword('');
      setNewUserRole('user');
      console.log(`Usuario ${newUserEmail} creado exitosamente`);
    } catch (error) {
      setError(error.message);
    }
  };

  const handlePasswordReset = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      console.log(`Correo de restablecimiento de contraseña enviado a ${email}`);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleEmailVerification = async () => {
    try {
      if (auth.currentUser) {
        await sendEmailVerification(auth.currentUser);
        console.log(`Correo de verificación enviado a ${auth.currentUser.email}`);
      } else {
        setError('No hay un usuario autenticado para verificar.');
      }
    } catch (error) {
      setError(error.message);
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      // Eliminar el documento del usuario de Firestore
      await deleteDoc(doc(db, 'users', userId));
      console.log(`Documento de usuario ${userId} eliminado de Firestore`);

      // Llamar a la función de Firebase para eliminar el usuario de Authentication
      const eliminarUsuario = httpsCallable(functions, 'eliminarUsuario');
      await eliminarUsuario({ uid: userId });

      console.log(`Usuario ${userId} eliminado de Firebase Authentication`);

      setUsuarios(usuarios.filter((user) => user.id !== userId));
    } catch (error) {
      setError(error.message);
    }
  };

  const handleUpdateUserRole = async (userId, newRole) => {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, { role: newRole });
      console.log(`Rol del usuario actualizado a: ${newRole}`);
      setUsuarios(usuarios.map((user) => (user.id === userId ? { ...user, role: newRole } : user)));
    } catch (error) {
      setError(error.message);
    }
  };

  if (loading) return <p>Cargando usuarios...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <h2>Gestión de Usuarios</h2>

      <form onSubmit={handleAddUser}>
        <h3>Añadir Nuevo Usuario</h3>
        <input
          type="email"
          placeholder="Correo Electrónico"
          value={newUserEmail}
          onChange={(e) => setNewUserEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Contraseña"
          value={newUserPassword}
          onChange={(e) => setNewUserPassword(e.target.value)}
          required
        />
        <select value={newUserRole} onChange={(e) => setNewUserRole(e.target.value)}>
          <option value="user">Usuario</option>
          <option value="admin">Administrador</option>
          <option value="super_admin">Super Administrador</option>
        </select>
        <button type="submit">Añadir Usuario</button>
      </form>

      <table>
        <thead>
          <tr>
            <th>Email</th>
            <th>Rol</th>
            <th>Verificado</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {usuarios.map((usuario) => (
            <tr key={usuario.id}>
              <td>{usuario.email}</td>
              <td>
                <select
                  value={usuario.role}
                  onChange={(e) => handleUpdateUserRole(usuario.id, e.target.value)}
                >
                  <option value="user">Usuario</option>
                  <option value="admin">Administrador</option>
                  <option value="super_admin">Super Administrador</option>
                </select>
              </td>
              <td>{usuario.emailVerified ? 'Sí' : 'No'}</td>
              <td>
                <button onClick={() => handlePasswordReset(usuario.email)}>Restablecer Contraseña</button>
                <button onClick={handleEmailVerification}>Enviar Verificación de Correo</button>
                <button onClick={() => handleDeleteUser(usuario.id)}>Eliminar Usuario</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Usuarios;
